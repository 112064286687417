<template>
  <div class="main-class">
    <div>
      <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" @submit.native.prevent>
        <el-form-item label="用户名称" prop="userName">
          <el-input
              v-model="queryParams.name"
              placeholder="请输入用户名称"
              clearable
             
              size="small"
              @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-col :span="10">
        <el-card shadow="hover">
          <!--表格-->
          <el-table v-loading="loading" :data="userList">
            <el-table-column label="编号" align="center" key="id" prop="id"/>
            <el-table-column label="标题" align="center" key="title" prop="title" :show-overflow-tooltip="true"/>

            <el-table-column label="创建时间" align="center" prop="time">
              <template slot-scope="scope">
                <span v-if="scope.row.time">{{ scope.row.time | dateYMDFormat }}</span>
                <span v-else>无</span>
              </template>
            </el-table-column>
            <el-table-column
                label="操作"
                align="center"
                class-name="small-padding fixed-width"
            >
              <template slot-scope="scope">
                <!--                <el-button-->
                <!--                    size="mini"-->
                <!--                    type="text"-->
                <!--                    @click="handleDelete(scope.row)"-->
                <!--                    v-permission="['sys:user:delete']"-->
                <!--                >删除</el-button>-->
                <el-button
                    size="mini"
                    type="text"
                    @click="addTab(scope.row)"
                >预览
                </el-button>

              </template>
            </el-table-column>
          </el-table>
          <Pagination
              v-show="total>0"
              :total="total"
              :small="true"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="getUserList"
          />
        </el-card>

      </el-col>
      <el-col :span="14">

          <el-card shadow="hover" class="card" style="margin-left: 15px">
            <el-tabs v-model="editableTabsValue" type="card" closable @tab-remove="removeTab">
              <el-tab-pane
                  v-for="(item) in editableTabs"
                  :key="item.name"
                  :label="item.title"
                  :name="item.name">
                <el-aside class="el-asidea" width="100%">
                <div v-html="item.content"></div>
                </el-aside>
              </el-tab-pane>
            </el-tabs>
          </el-card>

      </el-col>
    </div>
  </div>
</template>

<script>
export default {
  name: "User",
  data() {
    return {
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        name: undefined,
      }, // 总条数
      total: 0,
      showSearch: true,
      // 用户表格数据
      userList: null,
      //加载动画
      loading: false,
      editableTabsValue: '0',
      editableTabs: [],
      tabIndex: 0
    }
  },
  methods: {
    getUserList() {
      this.loading = true
      this.$axios.get("/movie/list", {
        params: {
          name: this.queryParams.name,
          pageNum: this.queryParams.pageNum,
          pageSize: this.queryParams.pageSize
        }
      }).then(res => {
        this.userList = res.data.data.records
        this.total = res.data.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      console.log("搜索信息", this.queryParams)
      this.getUserList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        name: undefined,
      }
      this.handleQuery();
    },

    // 右侧 Tab
    addTab(target) {
      let newTabName = ++this.tabIndex + '';
      this.editableTabs.push({
        title: target.time,
        name: newTabName,
        content: target.content
      });
      this.editableTabsValue = newTabName;
    },

    removeTab(targetName) {
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }
      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter(tab => tab.name !== targetName);
    },
  },
  created() {
    this.getUserList();
  }
}
</script>

<style lang="scss">
.main-class {
}

.el-table .el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
  margin-left: 5px;
}

.card {
  overflow-y: auto;
}

.el-asidea {
  height: calc(100vh - 70px);
}

.el-table .el-dropdown, .el-icon-arrow-down {
  font-size: 12px;
}
/*左侧垂直滚动条的宽高*/
.el-asidea::-webkit-scrollbar {
  width: 5px;
  // height: 16px;
}
/*修改左侧垂直滚动条的样式*/
.el-asidea::-webkit-scrollbar-thumb:vertical {
  background-color: #abcdef;
  -webkit-border-radius: 6px;
}
</style>
